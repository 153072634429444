var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-8" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Nom d'entreprise *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.companySettingsForm.company_name,
                                  expression: "companySettingsForm.company_name"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.companySettingsForm.company_name
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.companySettingsForm,
                                    "company_name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Email *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.companySettingsForm.company_email,
                                  expression:
                                    "companySettingsForm.company_email"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.companySettingsForm.company_email
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.companySettingsForm,
                                    "company_email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-password-input" } },
                              [_vm._v("N° Telephone")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.companySettingsForm.company_phone,
                                  expression:
                                    "companySettingsForm.company_phone"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.companySettingsForm.company_phone
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.companySettingsForm,
                                    "company_phone",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Site web")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.companySettingsForm.company_website,
                                  expression:
                                    "companySettingsForm.company_website"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.companySettingsForm.company_website
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.companySettingsForm,
                                    "company_website",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Adresse *")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.companySettingsForm.company_adr,
                                  expression: "companySettingsForm.company_adr"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { cols: "30", rows: "4" },
                              domProps: {
                                value: _vm.companySettingsForm.company_adr
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.companySettingsForm,
                                    "company_adr",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Image/Logo *")
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.onLogoSelectChange }
                        }),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showPreview,
                              expression: "showPreview"
                            }
                          ],
                          attrs: {
                            src: _vm.imagePreview,
                            width: "100",
                            height: "100"
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          disabled: _vm.$v.companySettingsForm.$invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }